<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">合同中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">合同查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="合同编号" class="searchboxItem ci-full">
              <span class="itemLabel">合同编号:</span>
              <el-input clearable placeholder="请输入合同编号" v-model="contractCode" size="small">
              </el-input>
            </div>
            <div title="合同名称" class="searchboxItem ci-full">
              <span class="itemLabel">合同名称:</span>
              <el-input v-model.trim="contractName" clearable placeholder="请输入合同名称" size="small"></el-input>
            </div>
            <div title="合同类型" class="searchboxItem ci-full">
              <span class="itemLabel">合同类型:</span>
              <el-select size="small" v-model="contractType" clearable placeholder="请选择合同类型">
                <el-option v-for="item in contractTypeList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="合同形式" class="searchboxItem ci-full">
              <span class="itemLabel">合同形式:</span>
              <el-select size="small" v-model="contractForm" clearable placeholder="请选择合同形式">
                <el-option v-for="item in contractFormList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="合同状态" class="searchboxItem ci-full">
              <span class="itemLabel">合同状态:</span>
              <el-select size="small" v-model="settleState" clearable placeholder="请选择合同状态">
                <el-option v-for="item in settleStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="审批状态" class="searchboxItem ci-full">
              <span class="itemLabel">审批状态:</span>
              <el-select size="small" v-model="auditType" clearable placeholder="请选择审批状态">
                <el-option v-for="item in ApprovalStatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="档案编号" class="searchboxItem ci-full">
              <span class="itemLabel">档案编号:</span>
              <el-input v-model="certNo" clearable placeholder="请输入档案编号" size="small"></el-input>
            </div>
            <div v-if="userJson.roleId != '-1'" title="对接业务员" class="searchboxItem ci-full">
              <span class="itemLabel">对接业务员:</span>
              <el-select size="small" v-model="saleId" clearable filterable placeholder="请选择对接业务员">
                <el-option v-for="item in salesmanList" :key="item.saleId" :label="item.saleName"
                  :value="item.saleId"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="代理商" class="searchboxItem ci-full">
              <span class="itemLabel">代理商:</span>
              <el-select size="small" v-model="agencyId" remote :remote-method="getAgencyList" filterable clearable
                placeholder="请至少输入两个字搜索">
                <el-option v-for="item in AgencyList" :key="item.agencyId" :label="item.agencyName"
                  :value="item.agencyId"></el-option>
              </el-select>
            </div>
            <div title="签订机构" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:5rem">签订机构:</span>
              <el-select size="small" v-model.trim="signCompId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="合同用途" class="searchboxItem ci-full">
              <span class="itemLabel">合同用途:</span>
              <el-select size="small" v-model="contractPurpose" clearable placeholder="请选择合同用途">
                <el-option v-for="item in contractPurposeList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="客户性质" class="searchboxItem ci-full">
              <span class="itemLabel">客户性质:</span>
              <el-select clearable placeholder="请选择客户性质" size="small" v-model="customerNature">
                <el-option v-for="item in customerNatureList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="实际培训机构" class="searchboxItem ci-full">
              <span class="itemLabel">实际培训机构:</span>
              <el-select size="small" v-model.trim="actualCompId" :filter-method='dataFilter' remote
                :remote-method="getactualList" filterable clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyactualList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="签订日期" class="searchboxItem ci-full">
              <span class="itemLabel">签订日期:</span>
              <el-date-picker clearable size="small" v-model="signingTime" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="所属行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader clearable filterable v-model="areaId" :options="areatreeList" :props="propsarea"
                size="small"></el-cascader>
            </div>
            <div class="df" style="padding-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button class="bgc-bv" style="margin: 0 10px" round @click="exportSeeExaminationRoom">导出</el-button>
              <el-button type="primary" class="bgc-bv" round @click="clearSearch()">清空搜索条件</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="合同编号" align="left" show-overflow-tooltip prop="contractCode" fixed
                min-width="180px" />
              <el-table-column label="合同名称" align="left" show-overflow-tooltip prop="contractName" fixed
                min-width="180px" />
              <el-table-column label="合同类型" align="left" show-overflow-tooltip prop="contractType" width="80px">
                <template slot-scope="scope">
                  {{
                    scope.row.contractType == "10"
                      ? "收款合同"
                      : scope.row.contractType == "20"
                        ? "付款合同"
                        : scope.row.contractType == "30" ? "备案合同" : "代理合同"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="合同形式" align="left" show-overflow-tooltip prop="contractForm" min-width="100px">
                <template slot-scope="scope">
                  {{ $setDictionary("CONTRACT_FORM", scope.row.contractForm) }}
                </template>
              </el-table-column>
              <el-table-column label="合同用途" align="left" show-overflow-tooltip prop="contractPurpose" min-width="100px">
                <template slot-scope="scope">
                  {{ $setDictionary("CONTRACT_PURPOSE", scope.row.contractPurpose) }}
                </template>
              </el-table-column>
              <el-table-column label="客户性质" align="left" show-overflow-tooltip min-width="100px">
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "CUSTOMER_NATURE",
                      scope.row.customerNature
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column label="实际培训机构" align="left" show-overflow-tooltip prop="actualCompName"
                min-width="220px" />
              <el-table-column label="签订机构" align="left" show-overflow-tooltip prop="signCompName" min-width="220px" />
              <el-table-column label="代理商" align="left" show-overflow-tooltip prop="agencyName" min-width="220px" />
              <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaNamePath" min-width="220px" />
              <el-table-column label="签订日期" align="left" show-overflow-tooltip prop="signDate" min-width="100px">
                <template slot-scope="scope">
                  {{ scope.row.signDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="有效期" align="left" show-overflow-tooltip prop="compName" min-width="180px">
                <template slot-scope="scope">
                  {{ scope.row.startDate.replaceAll("/", "-") }} ~
                  {{ scope.row.endDate.replaceAll("/", "-") }}
                </template>
              </el-table-column>
              <el-table-column label="结算标准" align="left" show-overflow-tooltip prop="settleStandardType"
                min-width="140px">
                <template slot-scope="scope">
                  <span v-if="scope.row.contractType != '20'">
                    {{
                      $setDictionary(
                        "SETTLEMENT_STANDARD",
                        scope.row.settleStandardType
                      )
                    }}
                  </span>
                  <span v-else>
                    {{
                      $setDictionary(
                        "SETTLEMENT_STANDARD_PAYMENT",
                        scope.row.settleStandardType
                      )
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="对接业务员" align="left" show-overflow-tooltip prop="saleName" min-width="100px" />
              <el-table-column label="最新负责人" align="left" show-overflow-tooltip prop="turnOverSaleName"
                min-width="100px" />
              <el-table-column label="合同状态" align="left" show-overflow-tooltip prop="createTime" min-width="80px">
                <template slot-scope="scope">
                  {{
                    scope.row.settleState == "10"
                      ? "未开始"
                      : scope.row.settleState == "20"
                        ? "有效"
                        : "已过期"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTime" min-width="150px">
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="审批状态" align="left" show-overflow-tooltip prop="auditType" min-width="120px">
                <template slot-scope="scope">
                  {{
                    $setDictionary("FD_CONTRACT_AUDIT_TYPE", scope.row.auditType)
                  }}
                </template>
              </el-table-column>
              <el-table-column label="档案编号" align="left" show-overflow-tooltip prop="certNo" min-width="120px" />
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" style="padding:0 5px"
                    @click="editModel(scope.row.contractId, scope.row.isApproval)">查看</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      contractCode: "", //合同编号
      contractName: "", //合同名称
      contractType: "", //合同类型
      contractForm: "", //合同形式
      contractPurpose: "", //合同用途
      signingTime: "", //签订日期
      certNo: '', //档案编号
      auditType: '', //审批状态
      CompanyList: [],
      CompanyactualList: [],
      signCompId: "",
      actualCompId: "",
      agencyId: '', //代理商
      areaId: "", // 行政区划
      customerNature: "", //客户性质
      customerNatureList: [], //客户性质list
      contractTypeList: [
        {
          label: "收款合同",
          value: "10",
        },
        {
          label: "付款合同",
          value: "20",
        },
        {
          label: "备案合同",
          value: "30",
        },
        {
          label: "代理合同",
          value: "40",
        },
      ],
      //合同状态
      settleStateList: [
        {
          label: "未开始",
          value: "10",
        },
        {
          label: "有效",
          value: "20",
        },
        {
          label: "已过期",
          value: "30",
        },
      ],
      settleState: "",
      salesmanList: [],
      saleId: "",
      AgencyList: [],
      ApprovalStatusList: [],
      // 合同形式
      contractFormList: [],
      // 合同用途 
      contractPurposeList: [],
      areatreeList: [], // 行政区划
      // 行政区划 - 数据格式
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  watch: {},
  created() {

  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getSalesmanList();
    this.getTableHeight();
    this.getCodeValue()
    this.getcontractFormList();
    this.getareatree();
  },
  methods: {
    // 获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 获取合同形式、合同用途字典
    getcontractFormList() {
      const list1 = this.$setDictionary("CONTRACT_FORM", "list");
      let contractFormList = [];
      for (const key in list1) {
        contractFormList.push({
          value: key,
          label: list1[key]
        });
      }
      this.contractFormList = contractFormList;
      const list2 = this.$setDictionary("CONTRACT_PURPOSE", "list");
      let contractPurposeList = [];
      for (const key in list2) {
        contractPurposeList.push({
          value: key,
          label: list2[key]
        });
      }
      this.contractPurposeList = contractPurposeList;
    },
    getSalesmanList() {
      this.$post('/biz/user/sale/selectList', { saleType: "10" })
        .then((ret) => {
          this.salesmanList = ret.data || [];
        })
        .catch((err) => {
          this.salesmanList = [];
          return;
        });
    },
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query.trim() })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /* 实际培训机构名称 */
    getactualList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query.trim() })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyactualList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyactualList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.contractCode) {
        params.contractCode = this.contractCode;
      }
      if (this.contractName) {
        params.contractName = this.contractName;
      }
      if (this.contractType) {
        params.contractType = this.contractType;
      }
      if (this.contractForm) {
        params.contractForm = this.contractForm;
      }
      if (this.contractPurpose) {
        params.contractPurpose = this.contractPurpose;
      }
      if (this.settleState) {
        params.settleState = this.settleState;
      }
      if (this.saleId) {
        params.saleId = this.saleId;
      }
      if (this.signCompId) {
        params.signCompId = this.signCompId;
      }
      if (this.actualCompId) {
        params.actualCompId = this.actualCompId;
      }
      if (this.signingTime) {
        params.signStartDate = this.signingTime[0];
        params.signEndDate = this.signingTime[1];
      }
      if (this.agencyId) {
        params.agencyId = this.agencyId;
      }
      if (this.certNo) {
        params.certNo = this.certNo;
      }
      if (this.auditType) {
        params.auditType = this.auditType;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.customerNature) {
        params.customerNature = this.customerNature;
      }
      this.doFetch({
        url: "/biz/new/bill/contract/pageList",
        params,
        pageNum,
      });
    },
    //编辑
    editModel(contractId, isApproval) {
      this.$router.push({
        path: "/web/financialSettlement/contractManagementLookDetail",
        query: {
          contractId,
          isApproval,
          stu: 'look'
        },
      });
    },
    // 导出
    exportSeeExaminationRoom() {
      const params = {};
      if (this.contractCode) {
        params.contractCode = this.contractCode;
      }
      if (this.contractName) {
        params.contractName = this.contractName;
      }
      if (this.contractType) {
        params.contractType = this.contractType;
      }
      if (this.settleState) {
        params.settleState = this.settleState;
      }
      if (this.contractForm) {
        params.contractForm = this.contractForm;
      }
      if (this.contractPurpose) {
        params.contractPurpose = this.contractPurpose;
      }
      if (this.saleId) {
        params.saleId = this.saleId;
      }
      if (this.signCompId) {
        params.signCompId = this.signCompId;
      }
      if (this.actualCompId) {
        params.actualCompId = this.actualCompId;
      }
      if (this.signingTime) {
        params.signStartDate = this.signingTime[0];
        params.signEndDate = this.signingTime[1];
      }
      if (this.agencyId) {
        params.agencyId = this.agencyId;
      }
      if (this.certNo) {
        params.certNo = this.certNo;
      }
      if (this.auditType) {
        params.auditType = this.auditType;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.customerNature) {
        params.customerNature = this.customerNature;
      }
      this.$post(
        "/biz/new/bill/contract/contractExport",
        params,
      )
        .then((res) => {
          const arr = [...res.data];
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyListPop = [res.data];
      });
    },
    //弹框关闭
    closeModel() {
      this.groupForm = {
        contractName: "",
        fileKey: "",
      };
      this.dialogFormVisible = false;
    },
    //弹框确定
    doOk(groupForm) {
      this.$refs[groupForm].validate((valid) => {
        console.log(this.groupForm);
        if (valid) {
          const params = {
            contractName: this.groupForm.contractName,
            fileKey: this.groupForm.fileKey,
            compId: this.groupForm.compId,
            fileName: this.groupForm.fileName,
          };

          if (this.contractId) {
            params.contractId = this.contractId;
          }
          this.$post(
            this.contractId
              ? "/biz/settlement/contract/modify"
              : "/biz/settlement/contract/insert",
            params
          ).then((ret) => {
            this.closeModel();
            this.getData();
          });
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4.5 + 5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    /* 清空 */
    clearSearch() {
      this.contractCode = "";
      this.contractName = "";
      this.contractType = "";
      this.contractForm = "";
      this.contractPurpose = "";
      this.signCompId = "";
      this.actualCompId = "";
      this.settleState = "";
      this.signingTime = "";
      this.saleId = "";
      this.agencyId = "";
      this.certNo = '';
      this.auditType = '';
      this.areaId = "";
      this.customerNature = "";
    },
    // 获取 - 代理商
    getAgencyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/mercantile/agency/getListByName", { agencyName: query })
          .then((res) => {
            if (res.status == 0) {
              this.AgencyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.AgencyList = [];
      }
    },
    getCodeValue() {
      const ApprovalStatus = this.$setDictionary(
        "FD_CONTRACT_AUDIT_TYPE",
        "list"
      );
      for (const key in ApprovalStatus) {
        this.ApprovalStatusList.push({
          value: key,
          label: ApprovalStatus[key],
        });
      }
      const customerNatureList = this.$setDictionary(
        "CUSTOMER_NATURE",
        "list"
      );
      for (const key in customerNatureList) {
        this.customerNatureList.push({
          value: key,
          label: customerNatureList[key],
        });
      }
    },
    dataFilter(val) {
      console.log(val);
      // if (val!='') {

      // }
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.query.refresh == true) {
          this.getData(-1);
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;

  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
</style>
